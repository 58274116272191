
import { mapMutations, mapState } from 'vuex'
import NeedHelpPopup from '~/components/UI/need-help-popup.vue'
// const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)
export default {
  components: { NeedHelpPopup },
  middleware: ['auth'],
  async asyncData ({ param, $axios, $store }) {
    try {
    // console.log('>>> case I <<<')
      const designList = await $axios.$get('/designs/get-designs')
      return { designList: designList.data, needLogin: false }
    } catch (error) {
      // console.log('>>> my design case II <<<', error.response)
      if (error && error.response && error.response.status !== 200) {
        return { designList: [], needLogin: true }
      }
    }
  },
  data () {
    return {
      currentTab: 'all',
      designList: [],
      hasDesign: false,
      needLogin: false,
      prepareDeleteData: { id: null, name: '' },
      urlDesigner: '',
      iframeHeight: '800px',
      drawingPNG: null
    }
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    const h =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
    this.iframeHeight = h - 10
    this.hideLoading()
    if (this.needLogin) {
      localStorage.setItem('back_url', this.localePath({ name: 'my-designs', query: this.$route.query }))
      this.$router.push(this.localePath({ name: 'auth-login-via-phone' }))
      return true
    } else {
      localStorage.removeItem('back_url')
    }
    window.objCurrentPage = this
    window.closeDesignNew = function () {
      window.objCurrentPage.$bvModal.hide('modalDesigner')
      return true
    }
    window.hideLoadingFromIframe = function () {
      window.objCurrentPage.hideLoading()
      return true
    }

    window.showLoadingFromIframe = function () {
      window.objCurrentPage.showLoading()
      return true
    }
    window.closeAndSaveDesignNew = function (uuid, saveOnly) {
      if (window.objCurrentPage && typeof window.objCurrentPage.reloadMyDesigns !== 'undefined') {
        window.objCurrentPage.reloadMyDesigns()
      }
      window.objCurrentPage.$bvModal.hide('modalDesigner')
      window.objCurrentPage.$bvModal.show('modal-warning-order')
      // window.objCurrentPage.uuid = uuid
      // const dataStore = {
      //   params: window.objCurrentPage.prepareData(false),
      //   factorySelected: window.objCurrentPage.factorySelected,
      //   fromModalDesigner: true
      // }
      // window.objCurrentPage.storeOrder2LocalStorage(dataStore)
      // if (saveOnly === false) {
      //   window.objCurrentPage.$bvModal.hide('modalDesigner')
      //   window.objCurrentPage.$bvModal.show('modal-warning-order')
      // }
      // window.objCurrentPage.getLastDesign()
      return true
    }
    window.closeAndSaveDesign = function (data) {
      window.objCurrentPage.$bvModal.hide('modalDesigner')
      window.objCurrentPage.setDrawingData(data)
      return true
    }
    window.clearSemiDesignEditMode = function () {
      if (window.objCurrentPage.query.semiDesignEditMode) {
        const query = Object.assign({}, window.objCurrentPage.$route.query)
        delete query.semiDesignEditMode
        window.objCurrentPage.$router.replace({ query })
        window.objCurrentPage.$cookies.remove('semiDesignEditMode')
        window.objCurrentPage.$cookies.remove('semiDesignData')
      }
    }
    window.savePngFromDesigner = function (data) {
      // console.log('savePngFromDesigner', data)
      window.objCurrentPage.setDrawingPNG(data)
      return true
    }
    window.returnDrawingData = function () {
      return window.objCurrentPage.drawingData
    }

    window.reloadMyDesigns = function () {
      if (window.objCurrentPage && typeof window.objCurrentPage.reloadMyDesigns !== 'undefined') {
        window.objCurrentPage.reloadMyDesigns()
      }
      return true
    }

    if (this.$route.query.flow && this.$route.query.flow === 'create') {
      // console.log('--- (debug design 1) ---')
      const action = 'create'
      // this.urlDesigner = `${this.apiDesignURL}/designer?flow=${action}`
      this.urlDesigner = `/designer?flow=${action}`
      // this.urlDesigner = 'http://localhost:3003/designer?flow=create'

      this.showLoading()
      this.$bvModal.show('modalDesigner')
      window.history.replaceState(null, null, '?')
    }

    if (this.$route.query.open) {
      // console.log('--- (debug design 2) ---')
      this.urlDesigner = `/designer?flow=edit&uuid=${this.$route.query.open}`
      this.showLoading()
      this.$bvModal.show('modalDesigner')
    }

    if (this.$store.state.userData.new_alert_design_count > 0) {
      // console.log('--- (debug design 3) ---')
      this.$axios.$put('clear-new-alert-design').then((res) => {
        if (res.success) {
          this.$store.commit('setNewAlertCount', res.data)
        }
      })
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine']),
    async reloadMyDesigns () {
      const designList = await this.$axios.$get('/designs/get-designs')
      this.designList = designList.data
    },
    setDrawingPNG (data) {
      this.drawingPNG = data
    },
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    deleteConfirmation (data) {
      this.prepareDeleteData = data
      this.$bvModal.show('deleteConfirmationModal')
    },
    async confirmDelete (id) {
      this.showLoading()
      this.$bvModal.hide('deleteConfirmationModal')
      await this.$axios.delete(`/designs/delete-design/${id}`).then(() => {
        this.prepareDeleteData = { id: null, name: '' }
        this.setAlert({
          show: true,
          message: 'ลบข้อมูลสำเร็จ',
          header: 'แจ้งเตือน'
        })
      })
      this.$axios.$get('/designs/get-designs').then((res) => {
        this.designList = res.data
        this.hideLoading()
      })
    },
    edit (design) {
      if (design.pattern_name) {
        this.urlDesigner = `/designer?flow=edit&uuid=${design.uuid}&patternName=${design.pattern_name}`
      } else {
        this.urlDesigner = `/designer?flow=edit&uuid=${design.uuid}`
      }

      this.showLoading()
      this.$bvModal.show('modalDesigner')
    },
    newDesign () {
      // console.log('customAxios', customAxios.baseURL)
      // console.log('newDesigngggg')
      // const action = 'create'
      // this.urlDesigner = '/designer?flow=create'
      // console.log('urllllll', this.urlDesigner)
      this.urlDesigner = '/designer?flow=create'
      // this.urlDesigner = 'http://localhost:3003/designer/?flow=create'
      // this.urlDesigner = this.drawingURL + 'designer?flow=create'
      // try {
      //   const res = this.$axios.get(this.urlDesigner)
      //   console.log('resss', res)
      // } catch (e) {
      //   console.log(e.message)
      // }
      this.showLoading()
      this.$bvModal.show('modalDesigner')
    },
    order (design) {
      if (design.rigids_id) {
        this.$router.push(this.localePath({ name: 'products-detail-id', params: { id: design.rigids_id }, query: { hasDesign: true, uuid: design.uuid } }))
      } else {
        this.$router.push(this.localePath({ name: 'customize-product-design-id', params: { design: true, id: design.mapping_input_id }, query: { hasDesign: true, uuid: design.uuid } }))
      }
    }
  }
}
