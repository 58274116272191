
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapMutations } from 'vuex'
import {
  getAuth, onAuthStateChanged, FacebookAuthProvider, updatePassword,
  GoogleAuthProvider, EmailAuthProvider, reauthenticateWithCredential
  // ,linkWithCredential
  , unlink, reauthenticateWithPopup
  , linkWithRedirect
} from 'firebase/auth'
import { BIconX, BTable, BRow, BCol, BPagination } from 'bootstrap-vue'
import ModalAlert from '~/components/modal/alert.vue'

export default {
  middleware: ['auth'],
  components: {
    BIconX,
    BTable,
    BRow,
    BCol,
    BPagination,
    ModalAlert,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      image: null,
      modalSetPassword: {
        show: false,
        header: '',
        email: '',
        oldPassword: '',
        newPassword: '',
        showPassword: false
      },
      userProfile: {},
      editUserProfile: {},
      loggedIn: {
        l_google: false,
        l_facebook: false,
        l_password: false
      },
      firebaseUser: null,
      userPoint: {},
      pointLogs: [], // Array to hold the point logs data
      pointLogsFields: [
        { key: 'created_at', label: 'วันที่ได้รับ' },
        { key: 'expiry_date', label: 'วันหมดอายุ' },
        { key: 'order_code', label: 'หมายเลขสั่งซื้อ' },
        { key: 'campaign_points_name', label: 'แคมเปญรับพอยท์' },
        { key: 'rewards_name', label: 'แคมเปญแลกพอยท์' },
        { key: 'receive', label: 'พอย์ทที่ได้รับ' },
        { key: 'used', label: 'พอย์ทที่ใช้' }
      ],
      currentPage: 1,
      perPage: 5
    }
  },
  computed: {
    countConnection () {
      let count = 0
      if (this.loggedIn.l_facebook) {
        count += 1
      }
      if (this.loggedIn.l_google) {
        count += 1
      }
      if (this.loggedIn.l_password) {
        count += 1
      }
      return count
    },
    totalRows () {
      return this.pointLogs.length
    },
    paginatedLogs () {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.pointLogs.slice(start, end)
    }
  },
  async mounted () {
    this.showLoading()
    try {
      const res = await this.$axios.get('me')
      this.userProfile = await res.data.user
      const auth = await getAuth()
      await onAuthStateChanged(auth, (user) => {
        if (user) {
          this.firebaseUser = user
          this.firebaseGetUser()
        } else {
          this.hideLoading()
          // this.signout()
        }
      })
      const pointUser = await this.$axios.get('point-users/user_id/' + res.data.user.id)
      this.userPoint = await pointUser.data
      const pointUserLogs = await this.$axios.post('point-user/logs', {
        user_id: res.data.user.id
      }).then(function (data) {
        return { error: false, data: data.data }
      }).catch(function (error) {
        return { error: true, message: error.message }
      })
      this.pointLogs = await pointUserLogs.data
    } catch (error) {
      if (error && error.response && error.response.status !== 200) {
        this.signout()
      }
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    async signout () {
      await this.showLoading()
      // localStorage.setItem('back_url', this.$router.push('auth/login-via-phone'))
      await this.$store.dispatch('logout')
      await this.hideLoading()
      localStorage.setItem('click-login-from-page', this.$route.name)
      localStorage.setItem('click-login-from-page-params', JSON.stringify(this.$route.params))
      localStorage.setItem('click-login-from-page-query', JSON.stringify(this.$route.query))
      this.$router.push(this.localePath({ name: 'auth-login-via-phone' }))
    },
    async firebaseUnlink (prov) {
      if (this.countConnection > 1) {
        this.showLoading()
        let providerId = null
        if (prov === 'facebook.com' || prov === 'google.com') {
          providerId = prov
        }
        if (providerId) {
          await unlink(this.firebaseUser, providerId)
          await this.firebaseGetUser()
        }
        this.hideLoading()
      } else {
        this.$refs['model-alert'].show = true
        this.$refs['model-alert'].msg = 'ไม่สามารถดำเนินการได้เนื่องจากช่องทางเชื่อมต่อเหลือเพียงช่องทางเดียว'
        this.$refs['model-alert'].header = 'ERROR'
      }
    },
    async firebaseLink (prov) {
      const auth = await getAuth()
      // console.log('firebaseLINKKKK', auth.currentUser)
      this.showLoading()
      let provider = null
      if (prov === 'facebook.com') {
        provider = new FacebookAuthProvider(auth)
      } else if (prov === 'google.com') {
        provider = new GoogleAuthProvider(auth)
      }
      if (provider) {
        const res = await linkWithRedirect(this.firebaseUser, provider).then(function (res) {
          return { error: false }
        }).catch(function (error) {
          // An error happened.
          return { error: true, message: error.message }
        })
        if (!res.error) {
          await this.firebaseGetUser()
        } else {
          this.$refs['model-alert'].show = true
          this.$refs['model-alert'].msg = res.message
          this.$refs['model-alert'].header = 'ERROR'
        }
      }
      this.hideLoading()
    },
    changePassword () {
      this.modalSetPassword.show = true
    },
    getCredential () {
      this.$refs['model-alert-button'].show = true
      this.$refs['model-alert-button'].msg = 'โปรดยืนยันการ Login อีกครั้ง ก่อนดำเนินการต่อ'
      this.$refs['model-alert-button'].header = 'ดำเนินการ'
    },
    async getCredentialAction () {
      const auth = getAuth()
      let res = { error: true, message: 'Cant authenication user.' }
      let provider = null
      if (this.loggedIn.l_google) {
        provider = new GoogleAuthProvider(auth)
      } else if (this.loggedIn.l_facebook) {
        provider = new FacebookAuthProvider(auth)
      }
      // provider = new firebase.auth.FacebookAuthProvider()
      if (provider) {
        res = await reauthenticateWithPopup(this.firebaseUser, provider).then(function (result) {
          return { error: false, result }
        }, function (error) {
          return { error: true, message: error.message }
        })
        this.$refs['model-alert-button'].show = false
        if (!res.error) {
          this.changePasswordAction()
        } else {
          this.$refs['model-alert'].show = true
          this.$refs['model-alert'].msg = res.message
          this.$refs['model-alert'].header = 'ERROR'
        }
      }
      return res
    },
    async changePasswordDo () {
      await this.$refs.validatePassword.validate().then(async (isValid) => {
        if (isValid) {
          if (this.loggedIn.l_password) {
            this.changePasswordAction()
          } else {
            await this.getCredential()
          }
        } else {
          /* something else if not valid */
          // console.log('invalid')
        }
      })
    },
    async changePasswordAction () {
      this.showLoading()
      const newPassword = this.modalSetPassword.newPassword

      if (this.loggedIn.l_password) {
        const credential = await EmailAuthProvider.credential(this.loggedIn.l_password, this.modalSetPassword.oldPassword)
        const res = await reauthenticateWithCredential(this.firebaseUser, credential).then(function (res) {
          // User re-authenticated.
          return { error: false }
        }).catch(function (error) {
          // An error happened.
          return { error: true, message: error.message }
        })
        if (!res.error) {
          const res = await updatePassword(this.firebaseUser, newPassword).then(function (res) {
            // User re-authenticated.
            return { error: false }
          }).catch(function (error) {
            // An error happened.
            return { error: true, message: error.message }
          })
          if (!res.error) {
            this.modalSetPassword.show = false
            this.modalSetPassword.newPassword = ''
            this.modalSetPassword.oldPassword = ''
            // console.log('success', res)
          } else {
            // console.log('failed', res)
            this.$refs['model-alert'].show = true
            this.$refs['model-alert'].msg = res.message
            this.$refs['model-alert'].header = 'ERROR'
          }
        } else {
          // console.log('failed', res)
          this.$refs['model-alert'].show = true
          this.$refs['model-alert'].msg = res.message
          this.$refs['model-alert'].header = 'ERROR'
        }
        // Prompt the user to re-provide their sign-in credentials
      } else {
        // const credential = await EmailAuthProvider.credential(this.modalSetPassword.email, this.modalSetPassword.newPassword)
        // console.log('>>>> FIREBASE USER <<<<', this.firebaseUser, credential)
        // const res = await linkWithCredential(this.firebaseUser, credential)
        //   .then(function (usercred) {
        //     return { error: false }
        //   }).catch(function (error) {
        //     return { error: true, message: error.message }
        //   })
        // if (!res.error) {
        //   this.modalSetPassword.show = false
        //   this.modalSetPassword.newPassword = ''
        //   this.modalSetPassword.oldPassword = ''
        //   // console.log('success', res)
        // } else {
        //   // console.log('failed', res)
        //   this.$refs['model-alert'].show = true
        //   this.$refs['model-alert'].msg = res.message
        //   this.$refs['model-alert'].header = 'ERROR'
        // }
      }

      await this.firebaseGetUser()
      this.hideLoading()
    },
    async setPassword () {
      this.showLoading()
      let credential
      // Prompt the user to re-provide their sign-in credentials
      await reauthenticateWithCredential(this.firebaseUser, credential)
      this.hideLoading()
    },
    async firebaseGetUser () {
      if (this.firebaseUser != null) {
        this.showLoading()
        this.loggedIn = {
          l_google: false,
          l_facebook: false,
          l_password: false
        }
        await this.firebaseUser.providerData.forEach(function (profile) {
          if (profile.providerId === 'facebook.com') {
            this.l_facebook = profile.email
          }
          if (profile.providerId === 'google.com') {
            this.l_google = profile.email
          }
          if (profile.providerId === 'password') {
            this.l_password = profile.email
          }
        }, this.loggedIn)
        // console.log(this.loggedIn)
        const obj = this

        const res = await this.$axios.post('updateProfile', {
          loggedIn: this.loggedIn
        }).then(function (usercred) {
          obj.hideLoading()
          return { error: false }
        }).catch(function (error) {
          obj.hideLoading()
          return { error: true, message: error.message }
        })

        if (res.error) {
          this.$refs['model-alert'].show = true
          this.$refs['model-alert'].msg = res.message
          this.$refs['model-alert'].header = 'ERROR'
        }
        this.hideLoading()
      }
    },
    handleEditProfileOk (bvModalEvt) {
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleEditProfileSubmit()
    },
    async handleEditProfileSubmit (bvModalEvt) {
      await this.$refs.validateEditProfile.validate().then(async (isValid) => {
        if (isValid) {
          this.showLoading()
          const res = await this.$axios.post('updateProfile', {
            name: this.editUserProfile.name,
            email: this.editUserProfile.email,
            phone: this.editUserProfile.phone
          }).then(function (data) {
            return { error: false, data: data.data }
          }).catch(function (error) {
            return { error: true, message: error.message }
          })
          if (res.error) {
            this.$refs['model-alert'].show = true
            this.$refs['model-alert'].msg = res.message
            this.$refs['model-alert'].header = 'ERROR'
          } else {
            this.userProfile = res.data.user
            await this.firebaseGetUser()
            this.$store.commit('setUserData', res.data.user)
            this.$store.commit('setUser', res.data.user)
          }
          this.hideLoading()
          this.$bvModal.hide('modalEditProfile')
        } else {
        }
      })
    },
    resetEditProfileModal () {
      this.editUserProfile = { ...this.userProfile }
    },
    async onChanged (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      const formData = new FormData()
      await formData.append('img', files[0])

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      this.showLoading()
      const res = await this.$axios.post('updateProfile', formData, config).then(function (data) {
        return { error: false, data: data.data }
      }).catch(function (error) {
        return { error: true, message: error.message }
      })

      if (res.error) {
        this.$refs['model-alert'].show = true
        this.$refs['model-alert'].msg = res.message
        this.$refs['model-alert'].header = 'ERROR'
      } else {
        this.userProfile = res.data.user
        this.$store.commit('setUserData', res.data.user)
        this.$store.commit('setUser', res.data.user)
      }
      this.hideLoading()
    },
    slideLeft (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft -= width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    slideRight (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft += width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('th-TH')
    }
  }
}
