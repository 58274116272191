
import { mapMutations, mapGetters, mapActions } from "vuex";
import PaymentType from '~/components/Payment/PaymentType.vue'
export default {
  middleware: ["auth"],
  name: "MyOrderIndex",
  components: {
    PaymentType
  },
  async asyncData({ param, $axios, $store }) {
    // try {
    //   const orderList = await $axios.$get('my-orders')
    //   console.log('debug asyncData :: orderList', orderList)
    //   let orderListData = []
    //   for (const k in orderList.data) {
    //     orderListData.push(orderList.data[k])
    //   }
    //   orderListData = orderListData.sort((a, b) => (a.id < b.id) ? 1 : -1)
    //   console.log('orderListData', orderListData)
    //   return { orderList: orderListData, needLogin: false }
    // } catch (error) {
    //   console.log('debug asyncData :: error', error)
    //   if (error && error.response && error.response.status !== 200) {
    //     return { orderList: [], needLogin: true }
    //   }
    // }
  },
  data() {
    return {
      currentTab: "all",
      orderList: [],
      needLogin: false,
      liffID: this.$line().orders.liffId,
      userProfile: null,
    };
  },
  created() {
    const isStaging = process.env.NODE_ENV !== "production";
    if (isStaging) {
      // Import VConsole only in the staging environment
      import("vconsole").then(({ default: VConsole }) => {
        const vConsole = new VConsole();
        console.log("vConsole", vConsole);
      });
    }
    if (process.client) {
      this.initializeLiff();
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    showList() {
      // console.log('show list', this.currentTab, this.orderList)
      if (this.currentTab === "all") {
        // console.log('--- all ---')
        return this.orderList;
      } else if (this.currentTab === "status1") {
        // console.log('--- status1 ---')
        return this.orderList.filter(o => {
          return o.order_status === 1;
        });
      } else if (this.currentTab === "status2") {
        // console.log('--- status2 ---')
        return this.orderList.filter(o => {
          return o.graphic_status === 0 || o.order_status === 2;
        });
      } else if (this.currentTab === "status3") {
        // console.log('--- status3 ---')
        return this.orderList.filter(o => {
          return o.graphic_status === 1 || o.graphic_status === 3;
        });
      } else if (this.currentTab === "status6") {
        // console.log('--- status6 ---')
        return this.orderList.filter(o => {
          return o.order_status === 6;
        });
      } else if (this.currentTab === "status7") {
        // console.log('--- status7 ---')
        return this.orderList.filter(o => {
          return o.order_status === 7;
        });
      } else {
        // console.log('--- status else ---')
        return this.orderList.filter(o => {
          return "status" + o.order_status === this.currentTab;
        });
      }
    }
  },
  async mounted() {
    // console.log("mounted!!>>", this.liffID);
    // const thisObject = this;
    // const isStaging = process.env.NODE_ENV !== "production";
    // if (isStaging) {
    //   // Import VConsole only in the staging environment
    //   import("vconsole").then(({ default: VConsole }) => {
    //     const vConsole = new VConsole();
    //     console.log("vConsole", vConsole);
    //   });
    // }
    // await window.liff.init({ liffId: this.liffID }).then(async () => {
    //   console.log('index liff init done')
    //   if (!window.liff.isInClient()) {
    //     this.lineProfile = await window.liff.getProfile()
    //     console.log('LINE PROFILE >>', this.lineProfile)
    //   }
    // })

    // if (!window.liff.isInClient()) {
    //   this.lineProfile = await window.liff.getProfile()
    //   console.log('LINE PROFILE >>', this.lineProfile)

      // try {
      //   await window.liff.init({ liffId: this.liffID });
      // } catch (e) {
      //   console.error("Error init liff", e);
      // }

      // console.log("<<< isInClient >>>");
      // this.lineProfile = await window.liff.getDecodedIDToken();
      // this.lineToken = await window.liff.getIDToken();
      // console.log("window onloaded!!!");
      // window.liff.init({ liffId: this.liffID }).then(async () => {
      // try {
      //   await thisObject.initializeApp(this.liffID);
      //   console.log("Window loaded! >>> ", thisObject.lineProfile,
      //     !thisObject.isTokenExpired(thisObject.lineProfile.exp));
      //   if (
      //     thisObject.lineProfile &&
      //     !thisObject.isTokenExpired(thisObject.lineProfile.exp)
      //   ) {
      //     thisObject.$axios
      //       .$get("line/user/token?token=" + thisObject.lineToken)
      //       .then(async (res) => {
      //         console.log(">>> res <<<", res);
      //         if (res.status) {
      //           console.log("ttttokennn", res.token);
      //           thisObject.$store.dispatch("authenticateSmsUser", res);
      //           await thisObject.getOrders();
      //         }
      //       }).catch((e) => {
      //         console.log('error line token', e.message)
      //       });
      //     }
      //   } catch (e) {
      //     console.log("!!!ERRROROR!!! ", e);
      //   }
      // }).catch((error) => {
      //   console.error('LIFF initialization failed', error);
      // });
      // await thisObject.hideLoading();
    // } else {
    //   console.log("cannot open from line.");
    //   if (this.isAuthenticated === false) {
    //     this.$router.push(
    //       this.localeLocation({ name: "auth-login-via-phone" })
    //     );
    //   }
    //   await this.getOrders();
    // }
  },
  methods: {
    ...mapMutations([
      "showLoading",
      "hideLoading",
      "setAlert",
      "setAlertWithLine"
    ]),
    ...mapActions([
      // "initializeApp",
      "isTokenExpired"
    ]),
    fileBaseName(url) {
      const path = require("path");
      return path.basename(url);
    },
    handleWindowLoad() {
      // console.log("handle window loaded");
    },
    // isTokenExpired (token) {
    //   try {
    //     const decodedToken = this.decodeBase64Url(token.split('.')[1])
    //     const expTimestamp = decodedToken.exp * 1000 // Convert seconds to milliseconds
    //     const currentTimestamp = Date.now()

    //     return expTimestamp < currentTimestamp
    //   } catch (error) {
    //     // Handle the error, such as an invalid or expired token
    //     console.error('Error decoding token:', error)
    //     return true // Assume token is expired for simplicity
    //   }
    // },
    isTokenExpired(expirationTime) {
      // Get the current time in seconds
      const currentTime = Math.floor(Date.now() / 1000);

      // Compare the current time with the expiration time
      return currentTime >= expirationTime;
    },
    decodeBase64Url(input) {
      const base64 = input.replace(/-/g, "+").replace(/_/g, "/");
      return JSON.parse(window.atob(base64));
    },
    async refreshLineToken(token) {
      // Implement your logic to refresh the Line token
      // This might involve making a request to the Line API
      // and exchanging the refresh token for a new access token.

      try {
        const response = await this.$axios.$get(
          "line/user/refresh-token?token=" + token
        );
        // console.log("refreshToken", response);
        if (response.status) {
          // Update the lineToken in your component or store
          this.lineToken = response.token;
          return true;
        } else {
          // Handle the case when token refresh fails
          console.error("Token refresh failed:", response.error);
          return false;
        }
      } catch (error) {
        // Handle network or other errors
        console.error("Error refreshing token:", error);
        return false;
      }
    },
    async handleToken() {
      if (this.isTokenExpired(this.lineToken)) {
        // Token has expired, refresh it
        const refreshSuccess = await this.refreshLineToken();
        // console.log("refreshToKen", refreshSuccess);
        if (refreshSuccess) {
          // Token refresh successful, perform authenticated actions
          // await this.getOrders()
        } else {
          // Handle the case when token refresh fails
          // You might want to redirect the user to the login page or display an error message
        }
      } else {
        // Token is still valid, perform authenticated actions
        // await this.getOrders()
      }
    },
    async initializeApp() {
      // console.log("*** initApp ***", this.liffID);

      try {
        // Ensure LIFF is initialized
        await window.liff.init({ liffId: this.liffID })
        if (!window.liff.isLoggedIn()) {
          console.log("not logged in");
        } else {
          const lineProfile = await window.liff.getProfile();
          console.log('* lineProfile *', lineProfile)
          // Check if the token is expired
          if (lineProfile !== null && this.isTokenExpired(lineProfile.exp)) {
            console.log("line token is expired");
            window.liff.logout();
          }
        }

        // console.log("initialized successfully");
        // ... remaining code ...
      } catch (error) {
        console.log("Error initializing LIFF:", error.message);
      }
    },
    async getOrders() {
      this.showLoading();
      try {
        const orderList = await this.$axios.$get("my-orders");
        // eslint-disable-next-line no-console
        let orderListData = [];
        for (const k in orderList.data) {
          orderListData.push(orderList.data[k]);
        }
        orderListData = orderListData.sort((a, b) => (a.id < b.id ? 1 : -1));
        this.orderList = orderListData;
        this.hideLoading();
        return { orderList: orderListData, needLogin: false };
      } catch (error) {
        this.hideLoading();
        // console.log('errrrrrrrr', error.message)
        if (error && error.response && error.response.status !== 200) {
          return { orderList: [], needLogin: true };
        }
      }
    },
    isImageFile(name) {
      const path = require("path");
      const ext = path.extname(name);
      if (
        ext === ".jpeg" ||
        ext === ".jpg" ||
        ext === ".png" ||
        ext === ".tiff" ||
        ext === ".gif"
      ) {
        return true;
      } else {
        return false;
      }
    },
    toText(status) {
      let ret = "";
      // if (status === 2) {
      //   ret = 'รอตรวจสอบกราฟิก'
      // } else
      if (status === 4) {
        ret = "อยู่ระหว่างจัดส่ง";
      } else if (status === 7) {
        ret = "อยู่ระหว่างการผลิต";
      } else if (status === 5) {
        ret = "จัดส่งเรียบร้อย";
      }
      return ret;
    },
    paymentStatus(order) {
      let ret = "";

      if (order.order_status === 6) {
        ret = "คำสั่งซื้อถูกยกเลิก";
      } else if (order.payment_status === 1) {
        ret = " รอการชำระเงิน";
      } else if (order.payment_status === 2) {
        ret = " กำลังตรวจสอบหลักฐานการชำระเงิน ";
      } else if (order.payment_status === 3) {
        ret = " ชำระเงินแล้ว";
      }

      let paymentType = "";
      if (parseInt(order.trans_2c2p_creditcard_id) > 0) {
        paymentType = "ผ่านช่องทาง Credit Card";
      } else if (parseInt(order.trans_2c2p_online_id) > 0) {
        paymentType = "ผ่านช่องทาง Online banking";
      } else {
        paymentType = "";
      }

      return ret + paymentType;
    },
    clickUpload(orderId, $event) {
      const ref = "fileUploadImage" + orderId;
      this.$refs[ref][0].click();
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async initializeLiff() {
      // console.log('*** initializeLiff ***', this.liffID)
      if (process.client) {
      if (window.liff.isInClient()) {
      try {
        await window.liff.init({ liffId: this.liffID });

        // Check if the user is logged in
        // console.log('isLoggedIn', window.liff.isLoggedIn());
        if (window.liff.isLoggedIn()) {
          // Check if the access token is still valid by attempting to get the user's profile
          try {
            const profile = await window.liff.getProfile();
            this.lineProfile = await window.liff.getDecodedIDToken();
            this.lineToken = await window.liff.getIDToken();
            this.userProfile = profile;

              if (
                this.lineProfile &&
                !this.isTokenExpired(this.lineProfile.exp)
              ) {
                this.$axios
                  .$get("line/user/token?token=" + this.lineToken)
                  .then(async (res) => {
                    // console.log(">>> res <<<", res);
                    if (res.status) {
                      // console.log("ttttokennn", res.token);
                      this.$store.dispatch("authenticateSmsUser", res);
                      await this.getOrders();
                    }
                  }).catch((e) => {
                    console.log('error line token', e.message)
                  });
                }
            // console.log('User profile:', profile, this.lineProfile);
          } catch (error) {
            // The access token is likely invalid or expired
            console.error('Error getting user profile:', error);
          }
        } else {
          // User is not logged in
          // console.log('User is not logged in');
          await window.liff.login();
        }
      } catch (error) {
        console.error('Error initializing LIFF:', error);
      }
    } else {
      // console.log("cannot open from line.");
      if (this.isAuthenticated === false) {
        this.$router.push(
          this.localeLocation({ name: "auth-login-via-phone" })
        );
      }
      await this.getOrders();
    }
    }
  }
  }
};
