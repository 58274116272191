import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c95a8f34&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=c95a8f34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c95a8f34",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=link&href=https%3A%2F%2Fcdn.jsdelivr.net%2Fnpm%2Fbootstrap%405.3.0%2Fdist%2Fcss%2Fbootstrap.min.css&rel=stylesheet&integrity=sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM&crossorigin=anonymous"
if (typeof block0 === 'function') block0(component)

export default component.exports